<template>
  <div class="view-box">
    <div class="view-box-title">KPI指标统计</div>
    <div><!-- class="view-content-box"-->
      <dv-border-box-7 :color="['#071d46', '#1957dc']" :reverse="true" class="data-card"
                       style="margin-left: 0px;">
        <div class="content">
          <span class="card-title">累计拉运趟数</span><br>
          <span class="card-value">{{kpiData.totalLoop}}<span class="unit"> 次</span></span><br>
        </div>
      </dv-border-box-7>
      <dv-border-box-7 :color="['#071d46', '#1957dc']" :reverse="true" class="data-card">
        <div class="content">
          <span class="card-title">累计拉运任务数</span><br>
          <span class="card-value">{{kpiData.totalTask}}<span class="unit"> 次</span></span><br>
        </div>
      </dv-border-box-7>
      <dv-border-box-7 :color="['#071d46', '#1957dc']" :reverse="true" class="data-card">
        <div class="content">
          <span class="card-title">累计拉运里程</span><br>
          <span class="card-value">{{Math.round(kpiData.totalMileage)}}<span class="unit"> km</span></span><br>
        </div>
      </dv-border-box-7>
      <dv-border-box-7 :color="['#071d46', '#1957dc']" :reverse="true" class="data-card">
        <div class="content">
          <span class="card-title">累计拉运吨数</span><br>
          <span class="card-value">{{Math.round(kpiData.totalTon)}}<span class="unit"> 吨</span></span><br>
        </div>
      </dv-border-box-7>
    </div>
  </div>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'RightOne',
    data () {
      return {
        kpiData: {}
      }
    },
    created () {
      this.getKPIData()
    },
    methods: {
      getKPIData () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.statisticsApi.dispatch_history_statistics,
          noTips: true,
          loading: 'no',
          data: {
            areaNo: ["04"]
          },
          success: (data) => {
            this.kpiData = data.body[0]
          },
          error: () => {
          }
        })
      }
    }
  }
</script>

<style scoped>
</style>