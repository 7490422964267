<template>
  <div>
    <div style="z-index: 100;position: relative;top: 20px;left: 60px;">
      <div
        style="width: 236px;float: left;text-align:left;font-size: large;font-weight: bold;line-height: 30px;padding-left: 6px;">
        <span @click="click">车辆清运一览图</span>
      </div>
<!--      <div style=" height: 180px;width: 100%;top: 30px;left: -65px; padding: 15px;line-height: 29px;font-size: 13px;position: absolute;z-index: 999">-->
<!--        <div style="text-indent: 2em;text-align: left;">兴庆区农村改厕及污水处理特许经营项目涉及掌政镇、 月牙湖乡、 通贵乡、<br/>大新镇共8347户农村居民卫生厕所改造。敷设<span class="strong">管线长99.6km</span>，-->
<!--          新建<span class="strong">集<br/>污池51</span>座， <span class="strong">一体化提升泵站22座</span>。-->
<!--          <span class="strong">一体三格化粪池3170座</span>。-->
<!--          从源头上<br/>解决了农村污水难收集，不能统一处理和排放的问题。-->
<!--        </div>-->
<!--      </div>-->
      <div style=" height: 180px;width: 100%;top: 30px;left: -65px; padding: 15px;line-height: 29px;font-size: 13px;position: absolute;z-index: 999">
        <div style="text-indent: 2em;text-align: left;">兴庆区农村改厕及污水处理工程，采用特许经营模式，由宁夏水务投资集团<br/>成立宁夏水投兴庆水务有限公司作为项目公司，负责2年特许经营期建设<br/>和13年运营维护。项目采用各村新建污水管网或单户三格式化粪池等方式<br/>将污水进行统一收集，拉运至现有污水站集中处理。对兴庆区2乡2镇<br/>（通贵乡、月牙湖乡、掌政镇、大新镇）农村户内厕所进行改造，<br/>总计铺设<span class="strong">管网99.6km</span>，新建<span class="strong">集污池51座，一体化泵站22座</span>。<br/><span class="strong">卫生厕所改造8347户</span>
          ，<span class="strong">一体三格式化粪池3170座</span>。
        </div>
      </div>
    </div>
    <div id="map"></div>
  </div>
</template>

<script>
import { Scene, PointLayer, LineLayer, PolygonLayer } from '@antv/l7'
import { GaodeMap } from '@antv/l7-maps'
import SERVICE_URLS from '@/api/service.url'

const pointData = {
  'type': 'FeatureCollection',
  'features': [{
    'type': 'Feature',
    'properties': {},
    'geometry': {
      'type': 'MultiPoint',
      'coordinates': [
        [
          106.5787124633789,
          38.62143058055104
        ],
        [
          106.5999984741211,
          38.58735682017159
        ],
        [
          106.56360626220702,
          38.54252654599825
        ],
        [
          106.58454895019531,
          38.47079371120379
        ],
        [
          106.51107788085938,
          38.44068226417387
        ],
        [
          106.4809513092041,
          38.352493773622996
        ],
        [
          106.44275665283203,
          38.456546110767654
        ],
        [
          106.31813049316406,
          38.481275996947794
        ]
      ]
    }
  }]
}
const lineData = {
  'type': 'FeatureCollection',
  'features': [{
    'type': 'Feature',
    'properties': {},
    'geometry': {
      'type': 'LineString',
      'coordinates': [
        [
          106.61819458007811,
          38.62223527691723
        ],
        [
          106.59759521484375,
          38.60238346454492
        ],
        [
          106.57218933105467,
          38.59648051509767
        ],
        [
          106.55776977539062,
          38.525070076783955
        ],
        [
          106.48704528808594,
          38.496593518947584
        ],
        [
          106.43486022949219,
          38.44498466889473
        ],
        [
          106.35177612304688,
          38.45251326003906
        ],
        [
          106.33872985839844,
          38.48154475346391
        ]
        // [
        //   106.35177612304688,
        //   38.45251326003906
        // ],
        // [
        //   106.39778137207031,
        //   38.39226254196437
        // ],
        // [
        //   106.493872,
        //   38.375589
        // ]
        // [
        //   106.38198852539062,
        //   38.35565723364132
        // ],
      ]
    }
  }]
}
const polygonData = {
  'type': 'FeatureCollection',
  'features': [{
    'type': 'Feature',
    'properties': {
      'adcode': 640104,
      'name': '兴庆区',
      'center': [
        106.278393,
        38.46747
      ],
      'centroid': [
        106.491637,
        38.465854
      ],
      'childrenNum': 0,
      'level': 'district',
      'acroutes': [
        100000,
        640000,
        640100
      ],
      'parent': { 'adcode': 640100 }
    },
    'geometry': {
      'type': 'MultiPolygon',
      'coordinates': [
        [
          [
            [
              106.376713,
              38.337226
            ],
            [
              106.371192,
              38.340477
            ],
            [
              106.366556,
              38.343467
            ],
            [
              106.360601,
              38.346457
            ],
            [
              106.358057,
              38.347954
            ],
            [
              106.357267,
              38.349273
            ],
            [
              106.354154,
              38.351679
            ],
            [
              106.361264,
              38.35764
            ],
            [
              106.367404,
              38.361861
            ],
            [
              106.375521,
              38.367839
            ],
            [
              106.376635,
              38.368857
            ],
            [
              106.377823,
              38.37169
            ],
            [
              106.379101,
              38.374563
            ],
            [
              106.379986,
              38.377157
            ],
            [
              106.380903,
              38.378957
            ],
            [
              106.383406,
              38.382572
            ],
            [
              106.378835,
              38.384365
            ],
            [
              106.375853,
              38.38535
            ],
            [
              106.372364,
              38.386741
            ],
            [
              106.368223,
              38.388585
            ],
            [
              106.36604,
              38.388817
            ],
            [
              106.36269,
              38.389516
            ],
            [
              106.358471,
              38.390493
            ],
            [
              106.355121,
              38.391482
            ],
            [
              106.353225,
              38.392185
            ],
            [
              106.345144,
              38.394416
            ],
            [
              106.349465,
              38.400087
            ],
            [
              106.344955,
              38.401101
            ],
            [
              106.343989,
              38.405631
            ],
            [
              106.343399,
              38.406344
            ],
            [
              106.340847,
              38.407141
            ],
            [
              106.337575,
              38.407836
            ],
            [
              106.334589,
              38.409042
            ],
            [
              106.332541,
              38.409737
            ],
            [
              106.3311,
              38.411059
            ],
            [
              106.330891,
              38.412547
            ],
            [
              106.331194,
              38.415121
            ],
            [
              106.331648,
              38.417583
            ],
            [
              106.328765,
              38.417666
            ],
            [
              106.327782,
              38.421203
            ],
            [
              106.325984,
              38.421783
            ],
            [
              106.323015,
              38.422456
            ],
            [
              106.320914,
              38.420324
            ],
            [
              106.319136,
              38.418633
            ],
            [
              106.317764,
              38.417558
            ],
            [
              106.317731,
              38.417333
            ],
            [
              106.313976,
              38.413988
            ],
            [
              106.305751,
              38.40654
            ],
            [
              106.302381,
              38.403343
            ],
            [
              106.299743,
              38.400746
            ],
            [
              106.297568,
              38.398389
            ],
            [
              106.292563,
              38.400435
            ],
            [
              106.287804,
              38.402336
            ],
            [
              106.284212,
              38.403585
            ],
            [
              106.282832,
              38.399761
            ],
            [
              106.271802,
              38.400464
            ],
            [
              106.270926,
              38.400547
            ],
            [
              106.269882,
              38.404972
            ],
            [
              106.269284,
              38.406826
            ],
            [
              106.269222,
              38.407471
            ],
            [
              106.269591,
              38.40839
            ],
            [
              106.273039,
              38.412713
            ],
            [
              106.2741,
              38.414401
            ],
            [
              106.27564,
              38.419064
            ],
            [
              106.275968,
              38.4207
            ],
            [
              106.275689,
              38.421833
            ],
            [
              106.269677,
              38.429298
            ],
            [
              106.268415,
              38.43139
            ],
            [
              106.267797,
              38.432965
            ],
            [
              106.266462,
              38.435962
            ],
            [
              106.264999,
              38.437822
            ],
            [
              106.263136,
              38.439712
            ],
            [
              106.262227,
              38.440797
            ],
            [
              106.261924,
              38.441449
            ],
            [
              106.261457,
              38.444605
            ],
            [
              106.261424,
              38.446342
            ],
            [
              106.259642,
              38.450732
            ],
            [
              106.258815,
              38.452523
            ],
            [
              106.258446,
              38.454448
            ],
            [
              106.258283,
              38.455921
            ],
            [
              106.25782,
              38.457813
            ],
            [
              106.25689,
              38.460918
            ],
            [
              106.255743,
              38.465636
            ],
            [
              106.255371,
              38.466732
            ],
            [
              106.253675,
              38.469011
            ],
            [
              106.253052,
              38.47052
            ],
            [
              106.253183,
              38.47404
            ],
            [
              106.25304,
              38.475751
            ],
            [
              106.252991,
              38.481709
            ],
            [
              106.253253,
              38.483561
            ],
            [
              106.255309,
              38.487854
            ],
            [
              106.255964,
              38.488874
            ],
            [
              106.259331,
              38.49605
            ],
            [
              106.26176,
              38.50104
            ],
            [
              106.281009,
              38.502827
            ],
            [
              106.295647,
              38.506783
            ],
            [
              106.302172,
              38.510898
            ],
            [
              106.303642,
              38.511491
            ],
            [
              106.305407,
              38.513888
            ],
            [
              106.308643,
              38.51955
            ],
            [
              106.309909,
              38.52126
            ],
            [
              106.312309,
              38.52195
            ],
            [
              106.314717,
              38.521249
            ],
            [
              106.316982,
              38.520049
            ],
            [
              106.318878,
              38.520103
            ],
            [
              106.322319,
              38.521929
            ],
            [
              106.324813,
              38.523577
            ],
            [
              106.329793,
              38.525515
            ],
            [
              106.332128,
              38.525786
            ],
            [
              106.334311,
              38.523841
            ],
            [
              106.336559,
              38.520287
            ],
            [
              106.337714,
              38.518566
            ],
            [
              106.337915,
              38.516683
            ],
            [
              106.337837,
              38.515027
            ],
            [
              106.33848,
              38.513534
            ],
            [
              106.343739,
              38.512941
            ],
            [
              106.349215,
              38.512644
            ],
            [
              106.352283,
              38.512131
            ],
            [
              106.355268,
              38.511079
            ],
            [
              106.358631,
              38.511075
            ],
            [
              106.361707,
              38.512901
            ],
            [
              106.36346,
              38.513526
            ],
            [
              106.364537,
              38.510945
            ],
            [
              106.364598,
              38.508772
            ],
            [
              106.363632,
              38.506711
            ],
            [
              106.361944,
              38.504827
            ],
            [
              106.360335,
              38.503568
            ],
            [
              106.358426,
              38.501916
            ],
            [
              106.354756,
              38.498372
            ],
            [
              106.354302,
              38.496943
            ],
            [
              106.355244,
              38.495569
            ],
            [
              106.35773,
              38.49511
            ],
            [
              106.359757,
              38.493497
            ],
            [
              106.360036,
              38.491887
            ],
            [
              106.360613,
              38.489775
            ],
            [
              106.361096,
              38.486339
            ],
            [
              106.361088,
              38.484671
            ],
            [
              106.358954,
              38.482505
            ],
            [
              106.356468,
              38.480222
            ],
            [
              106.357181,
              38.478909
            ],
            [
              106.364045,
              38.478768
            ],
            [
              106.367621,
              38.478812
            ],
            [
              106.370467,
              38.479155
            ],
            [
              106.374121,
              38.479763
            ],
            [
              106.376689,
              38.48057
            ],
            [
              106.378581,
              38.481239
            ],
            [
              106.380948,
              38.481828
            ],
            [
              106.382963,
              38.481695
            ],
            [
              106.385843,
              38.480012
            ],
            [
              106.386952,
              38.478812
            ],
            [
              106.38726,
              38.47816
            ],
            [
              106.387579,
              38.476478
            ],
            [
              106.387395,
              38.473921
            ],
            [
              106.385732,
              38.469435
            ],
            [
              106.385191,
              38.468733
            ],
            [
              106.381341,
              38.465795
            ],
            [
              106.38019,
              38.464706
            ],
            [
              106.378732,
              38.462998
            ],
            [
              106.37745,
              38.461265
            ],
            [
              106.373379,
              38.456283
            ],
            [
              106.375501,
              38.456239
            ],
            [
              106.378929,
              38.456507
            ],
            [
              106.38112,
              38.4565
            ],
            [
              106.384389,
              38.455458
            ],
            [
              106.387161,
              38.454759
            ],
            [
              106.390004,
              38.454466
            ],
            [
              106.391892,
              38.454061
            ],
            [
              106.394149,
              38.454162
            ],
            [
              106.395906,
              38.455237
            ],
            [
              106.398834,
              38.456663
            ],
            [
              106.400509,
              38.457404
            ],
            [
              106.40415,
              38.457611
            ],
            [
              106.408377,
              38.457759
            ],
            [
              106.409073,
              38.457878
            ],
            [
              106.412198,
              38.459517
            ],
            [
              106.412669,
              38.462593
            ],
            [
              106.41543,
              38.46256
            ],
            [
              106.414926,
              38.46044
            ],
            [
              106.417658,
              38.460939
            ],
            [
              106.418338,
              38.459438
            ],
            [
              106.419403,
              38.45938
            ],
            [
              106.421278,
              38.460165
            ],
            [
              106.423994,
              38.458834
            ],
            [
              106.425988,
              38.458095
            ],
            [
              106.421962,
              38.451915
            ],
            [
              106.425616,
              38.450771
            ],
            [
              106.425718,
              38.450261
            ],
            [
              106.426951,
              38.450243
            ],
            [
              106.432181,
              38.448781
            ],
            [
              106.43239,
              38.449038
            ],
            [
              106.431886,
              38.450815
            ],
            [
              106.432214,
              38.454271
            ],
            [
              106.431956,
              38.458334
            ],
            [
              106.432771,
              38.460574
            ],
            [
              106.434757,
              38.46336
            ],
            [
              106.437817,
              38.465813
            ],
            [
              106.439398,
              38.465545
            ],
            [
              106.441814,
              38.463993
            ],
            [
              106.444648,
              38.461779
            ],
            [
              106.447941,
              38.460487
            ],
            [
              106.449059,
              38.460668
            ],
            [
              106.451173,
              38.462173
            ],
            [
              106.451762,
              38.463367
            ],
            [
              106.449952,
              38.464565
            ],
            [
              106.449068,
              38.463892
            ],
            [
              106.445783,
              38.465426
            ],
            [
              106.442281,
              38.466309
            ],
            [
              106.442699,
              38.46687
            ],
            [
              106.441691,
              38.467412
            ],
            [
              106.4419,
              38.468458
            ],
            [
              106.442994,
              38.468765
            ],
            [
              106.443661,
              38.46959
            ],
            [
              106.446115,
              38.471649
            ],
            [
              106.44833,
              38.472503
            ],
            [
              106.448384,
              38.47294
            ],
            [
              106.44686,
              38.473208
            ],
            [
              106.446676,
              38.47438
            ],
            [
              106.447102,
              38.475621
            ],
            [
              106.446999,
              38.477918
            ],
            [
              106.445058,
              38.477336
            ],
            [
              106.444857,
              38.478193
            ],
            [
              106.448306,
              38.480667
            ],
            [
              106.451484,
              38.482349
            ],
            [
              106.451443,
              38.483203
            ],
            [
              106.449694,
              38.484472
            ],
            [
              106.449649,
              38.485066
            ],
            [
              106.45193,
              38.488158
            ],
            [
              106.453151,
              38.489269
            ],
            [
              106.452381,
              38.490158
            ],
            [
              106.45252,
              38.490737
            ],
            [
              106.449088,
              38.491547
            ],
            [
              106.447745,
              38.491688
            ],
            [
              106.447335,
              38.492061
            ],
            [
              106.447847,
              38.493294
            ],
            [
              106.44772,
              38.49362
            ],
            [
              106.445787,
              38.493721
            ],
            [
              106.449195,
              38.507672
            ],
            [
              106.446127,
              38.507683
            ],
            [
              106.443805,
              38.514224
            ],
            [
              106.442944,
              38.517898
            ],
            [
              106.442494,
              38.520595
            ],
            [
              106.442826,
              38.521513
            ],
            [
              106.442322,
              38.522446
            ],
            [
              106.450608,
              38.520674
            ],
            [
              106.452848,
              38.519929
            ],
            [
              106.458479,
              38.520334
            ],
            [
              106.466065,
              38.520638
            ],
            [
              106.467617,
              38.520837
            ],
            [
              106.467621,
              38.522196
            ],
            [
              106.467781,
              38.523494
            ],
            [
              106.468444,
              38.525547
            ],
            [
              106.468948,
              38.525677
            ],
            [
              106.471692,
              38.525121
            ],
            [
              106.474289,
              38.524792
            ],
            [
              106.476169,
              38.524372
            ],
            [
              106.480764,
              38.524575
            ],
            [
              106.48214,
              38.524748
            ],
            [
              106.483189,
              38.523093
            ],
            [
              106.486535,
              38.522876
            ],
            [
              106.488034,
              38.523458
            ],
            [
              106.488959,
              38.523396
            ],
            [
              106.489676,
              38.521697
            ],
            [
              106.490102,
              38.521813
            ],
            [
              106.490127,
              38.523028
            ],
            [
              106.492576,
              38.523064
            ],
            [
              106.494227,
              38.52199
            ],
            [
              106.494722,
              38.521097
            ],
            [
              106.495414,
              38.520529
            ],
            [
              106.497429,
              38.521184
            ],
            [
              106.498519,
              38.522561
            ],
            [
              106.501599,
              38.52332
            ],
            [
              106.504097,
              38.523078
            ],
            [
              106.504843,
              38.523118
            ],
            [
              106.506366,
              38.525208
            ],
            [
              106.507681,
              38.525547
            ],
            [
              106.50884,
              38.525612
            ],
            [
              106.509569,
              38.525377
            ],
            [
              106.513488,
              38.524976
            ],
            [
              106.515467,
              38.52502
            ],
            [
              106.52016,
              38.524224
            ],
            [
              106.525513,
              38.52195
            ],
            [
              106.532325,
              38.5213
            ],
            [
              106.536785,
              38.522518
            ],
            [
              106.544767,
              38.523819
            ],
            [
              106.54507,
              38.525338
            ],
            [
              106.539111,
              38.536272
            ],
            [
              106.537788,
              38.543667
            ],
            [
              106.537657,
              38.549056
            ],
            [
              106.537174,
              38.552568
            ],
            [
              106.536088,
              38.556207
            ],
            [
              106.534135,
              38.559669
            ],
            [
              106.53026,
              38.56463
            ],
            [
              106.527762,
              38.568398
            ],
            [
              106.526607,
              38.571295
            ],
            [
              106.526414,
              38.573514
            ],
            [
              106.526705,
              38.57631
            ],
            [
              106.528147,
              38.57968
            ],
            [
              106.531223,
              38.583242
            ],
            [
              106.538722,
              38.589295
            ],
            [
              106.540115,
              38.591679
            ],
            [
              106.542158,
              38.599935
            ],
            [
              106.544759,
              38.609309
            ],
            [
              106.547565,
              38.615661
            ],
            [
              106.553389,
              38.622524
            ],
            [
              106.562645,
              38.631574
            ],
            [
              106.570136,
              38.636671
            ],
            [
              106.578741,
              38.644845
            ],
            [
              106.581899,
              38.649266
            ],
            [
              106.587108,
              38.655726
            ],
            [
              106.604073,
              38.653564
            ],
            [
              106.614533,
              38.650154
            ],
            [
              106.633344,
              38.645235
            ],
            [
              106.639562,
              38.642954
            ],
            [
              106.642421,
              38.640738
            ],
            [
              106.643043,
              38.638847
            ],
            [
              106.64163,
              38.619989
            ],
            [
              106.64969,
              38.613964
            ],
            [
              106.662522,
              38.601477
            ],
            [
              106.662424,
              38.601213
            ],
            [
              106.657661,
              38.555546
            ],
            [
              106.65137,
              38.492553
            ],
            [
              106.648757,
              38.472727
            ],
            [
              106.648175,
              38.4715
            ],
            [
              106.647024,
              38.469843
            ],
            [
              106.646631,
              38.469084
            ],
            [
              106.643846,
              38.464543
            ],
            [
              106.642887,
              38.4631
            ],
            [
              106.642199,
              38.461862
            ],
            [
              106.640958,
              38.46014
            ],
            [
              106.639287,
              38.457564
            ],
            [
              106.637887,
              38.455885
            ],
            [
              106.614341,
              38.413448
            ],
            [
              106.601849,
              38.392507
            ],
            [
              106.599776,
              38.389968
            ],
            [
              106.597352,
              38.388175
            ],
            [
              106.587542,
              38.382645
            ],
            [
              106.511412,
              38.33678
            ],
            [
              106.487477,
              38.322611
            ],
            [
              106.482468,
              38.319508
            ],
            [
              106.482398,
              38.320102
            ],
            [
              106.482927,
              38.32525
            ],
            [
              106.478167,
              38.32766
            ],
            [
              106.473625,
              38.333261
            ],
            [
              106.4739,
              38.337451
            ],
            [
              106.472933,
              38.338893
            ],
            [
              106.47057,
              38.337922
            ],
            [
              106.469427,
              38.33673
            ],
            [
              106.467326,
              38.293973
            ],
            [
              106.436121,
              38.303775
            ],
            [
              106.435916,
              38.307412
            ],
            [
              106.43796,
              38.316745
            ],
            [
              106.437407,
              38.329473
            ],
            [
              106.431165,
              38.331075
            ],
            [
              106.421213,
              38.339513
            ],
            [
              106.426484,
              38.345511
            ],
            [
              106.412276,
              38.356825
            ],
            [
              106.406067,
              38.352708
            ],
            [
              106.398682,
              38.348421
            ],
            [
              106.392518,
              38.346102
            ],
            [
              106.383553,
              38.341477
            ],
            [
              106.376713,
              38.337226
            ]
          ]
        ]
      ]
    }
  }]
}

export default {
  name: 'XQCenterMap',
  components: {},
  data () {
    return {
      scene: null,
      qyCarList: [], //所有清运车辆
      carPointData: {},
      doingCarList: [], //清运中车辆
      carDoingPointData: {}
    }
  },
  mounted () {
    this.initScene()
    this.init()
  },
  destroyed () {
    if (this.scene) {
      this.scene.destroy()
    }
    this.scene = null
  },
  methods: {
    click () {
      this.$router.push('/dispatch/panel/xq')
    },
    initScene () {
      if (!this.scene) {
        try {
          window.dpInit = true
          this.scene = new Scene({
            id: 'map',
            map: new GaodeMap({
              pitch: 0,
              /*style: 'dark',*/
              style: 'amap://styles/darkblue',
              center: [106.39297485351561, 38.54198948702892],
              zoom: 10.5,
              token: 'b5f28a045617f7a5d8e7cd14ba3ada48'
            })
          })
        } catch (e) {
        }
      }
    },
    init () {
      this.initScene()
      this.getCarStatus()
      // this.loadPoint()
      /*this.loadPoint2()*/
      this.loadPolygon()
      /*this.loadLine()*/
    },
    //加载清运车辆是否清运中显示
    getCarStatus () {
      this.$http(this, {
        url: SERVICE_URLS.csgz.dispatchDatavApi.getCarStatus,
        noTips: true,
        data: {
          useType: 'QY'
        },
        loading: 'no',
        success: (data) => {
          this.qyCarList = data.body
          this.doingCarList = []
          var carCoordinates = []
          var carDoingCoordinates = []
          for (let i = 0; i < this.qyCarList.length; i++) {
            if (this.qyCarList[i].longitude !== '0' && this.qyCarList[i].latitude !== '0') {
              let catItem = []
              catItem.push(parseFloat(this.qyCarList[i].longitude))
              catItem.push(parseFloat(this.qyCarList[i].latitude))
              // carCoordinates.push(catItem)
              carCoordinates.push([parseFloat(this.qyCarList[i].longitude), parseFloat(this.qyCarList[i].latitude)])
            }
            if (this.qyCarList[i].status === 'doing') {
              if (this.qyCarList[i].longitude !== '0' && this.qyCarList[i].latitude !== '0') {
                let catDoingItem = []
                catDoingItem.push(parseFloat(this.qyCarList[i].longitude))
                catDoingItem.push(parseFloat(this.qyCarList[i].latitude))
                // carDoingCoordinates.push(catDoingItem)
                carDoingCoordinates.push([parseFloat(this.qyCarList[i].longitude), parseFloat(this.qyCarList[i].latitude)])
              }
            }
          }
          this.carPointData = {
            'type': 'FeatureCollection',
            'features': [{
              'type': 'Feature',
              'properties': {},
              'geometry': {
                'type': 'MultiPoint',
                'coordinates': carCoordinates
              }
            }]
          }
          this.carDoingPointData = {
            'type': 'FeatureCollection',
            'features': [{
              'type': 'Feature',
              'properties': {},
              'geometry': {
                'type': 'MultiPoint',
                'coordinates': carDoingCoordinates
              }
            }]
          }
          this.loadDoingCarPoint()
          this.loadCarPoint()
        },
        error: () => {
        }
      })
    },
    loadCarPoint () {
      this.scene.addImage('local', 'http://sangefile.fangshuoit.com/o_1ep2pcnam9d51oba1haa1uac1un3e.png')
      const pointLayer = new PointLayer({})
        .source(this.carPointData)
        .shape('local')
        .active(false)
        .animate(false)
        .size(15)
        // .color('#F06154')
        .style({
          opacity: 1
        })
      this.scene.addLayer(pointLayer)
      // pointLayer.on('click', e => {
      //   console.info('???????', e)
      //   const popup = new Popup({
      //     offsets: [0, 0],
      //     closeButton: false
      //   })
      //     .setLnglat(e.lngLat)
      //     .setHTML(`<span>车牌号: ${e.feature.properties.name}</span><br><span>清运信息: ${e.feature.properties.case}</span>`)
      //   this.scene.addPopup(popup)
      // })
    },
    loadDoingCarPoint () {
      const pointLayer = new PointLayer({})
        .source(this.carDoingPointData)
        .shape('circle')
        .active(false)
        .animate(true)
        .size(50)
        .color('#4cfd47')
        .style({
          opacity: 1,
          offsets: [0, 0]
        })
      this.scene.addLayer(pointLayer)
    },
    loadPolygon () {
      const polygonLayer = new PolygonLayer({
        autoFit: true
      })
        .source(polygonData)
        .color(
          'name',
          [
            'rgb(49,130,189)',
            'rgb(8,81,156)'
          ]
        )
        .shape('fill')
        .style({
          opacity: 0.1
        })
      this.scene.addLayer(polygonLayer)
      const polygonLayerLine = new PolygonLayer({
        autoFit: true
      })
        .source(polygonData)
        .color(
          'name',
          [
            'rgb(8,81,156)'
          ]
        )
        .shape('line')
        .size(1.5)
        .style({
          opacity: 0.6
        })
      this.scene.addLayer(polygonLayer)
      this.scene.addLayer(polygonLayerLine)
    },
    loadPoint () {
      this.scene.on('loaded', () => {
        fetch(
          '/mapdata/ningxia-points.csv'
        )
          .then(res => res.text())
          .then(data => {
            const pointLayer = new PointLayer({})
              .source(data, {
                parser: {
                  type: 'csv',
                  x: 'lon',
                  y: 'lat'
                }
              })
              .shape('circle')
              .active(true)
              .animate(true)
              .size(30)
              .color('#4cfd47')
              .style({
                opacity: 1
              })
            this.scene.addLayer(pointLayer)
          })
      })
    },
    loadPoint2 () {
      const pointLayer = new PointLayer({})
        .source(pointData)
        .shape('circle')
        .active(true)
        .animate(true)
        .size(34)
        .color('#4cfd47')
        .style({
          opacity: 1
        })
      this.scene.addLayer(pointLayer)
    },
    loadLine () {
      /*
      duration 动画时间 单位(s)秒
      interval 轨迹间隔, 取值区间 0 - 1
      trailLength 轨迹长度 取值区间 0 - 1*/
      const lineLayer = new LineLayer({})
        .source(lineData)
        .active(true)
        .animate({
          duration: 3,
          interval: 2,
          trailLength: 0.4
        })
        .color('#4cfd47')
        .size(1.5)
        .style({
          opacity: 1
        })
      this.scene.addLayer(lineLayer)
    }
  }
}
</script>

<style lang="less">
.strong {
  color: #09ff99;
  font-size: 13px
}
</style>